// src/pages/Management.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation"; // Import the Navigation component
import "../styles/Management.css";
import SuccessModal from "../components/SuccessModal";

const Management = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    if (true) {
      setShowModal(true);
    }
  };
  const handleDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/management.pdf`; // Path to PDF file in the public folder
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Management_Guide.pdf";
    link.click();
  };

  return (
    <div className="management">
      <Navigation />

      <div className="management-header">
        <h1>Management Resources</h1>
      </div>

      <div className="management-content">
        <p>Access important management resources and guidelines here.</p>
        <button onClick={handleDownload} className="download-button">
          Download PDF
        </button>
      </div>

      <div className="online-training">
        <h2>Online Training Resources</h2>
        <p>
          Explore a curated list of online training modules designed to help you
          enhance your management skills. These resources cover topics such as
          leadership, team management, and strategic planning.
        </p>
        <a
          href="https://www.example.com/training"
          target="_blank"
          rel="noopener noreferrer"
          className="training-link"
        >
          Access Training Resources
        </a>
      </div>
      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>

      {/* <Link to="/" className="back-home">Back to Home</Link> */}
      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Management;
