// components/Navigation.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navigation.css';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="navigation">
      {/* Render the button outside the popup when menu is closed */}
      {!isOpen && (
        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>
      )}
      
      {/* Render the menu popup */}
      {isOpen && (
        <div className="menu-popup">
          {/* Render the button inside the popup, positioned at the top-right */}
          <button className="menu-button inside-popup" onClick={toggleMenu}>
            ☰
          </button>
          
          <div className="menu">
            <Link to="/" onClick={toggleMenu}>Home</Link>
            <Link to="/about" onClick={toggleMenu}>About</Link>
            <Link to="/prices" onClick={toggleMenu}>Prices</Link>
            <Link to="/contact" onClick={toggleMenu}>Contact</Link>
            <Link to="/management" onClick={toggleMenu}>Management</Link>
            <Link to="/portfolio" onClick={toggleMenu}>Portfolio</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigation;
