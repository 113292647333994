import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import "../styles/Contact.css";
import logo from "../assets/logo.png";
import SuccessModal from "../components/SuccessModal";

const Prices = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    if (true) {
      setShowModal(true);
    }
  };

  return (
    <div className="contact">
      <Navigation /> {/* Add the Navigation component */}
      <div className="contact-header">
        <img
          src={logo}
          alt="Noosa Airbnb Approvals Logo"
          className="contact-logo"
        />
      </div>
      <div className="contact-content">
        <p>
          {" "}
          <strong>Please note that our office hours are:</strong>
        </p>
        <p>
          Monday - Friday: 8am - 6pm
          <br />
          Weekends by appointment only
        </p>

        <p>
          <strong>Contact information:</strong>
        </p>
        <p>
          +61 479 088 029
          <br />
          office@noosaairbnbapprovals.com.au
        </p>
      </div>
      <button className="cta-button" onClick={handleSubmit}>
        Get Your Approval Here
      </button>
      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Prices;
