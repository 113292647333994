import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import "../styles/Prices.css";
import logo from "../assets/logo.png";
import SuccessModal from "../components/SuccessModal";

const Prices = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    if (true) {
      setShowModal(true);
    }
  };
  return (
    <div className="prices">
      <Navigation />

      <div className="prices-header">
        <img
          src={logo}
          alt="Noosa Airbnb Approvals Logo"
          className="prices-logo"
        />
      </div>

      <div className="prices-content">
        <p style={{ textAlign: "center" }}>
          {" "}
          <strong>Fixed Price Approvals</strong>
        </p>

        <p style={{ textAlign: "center" }}>
          Unit $1295.95
          <br />
          House $1695.95
        </p>
        <p
          style={{
            fontSize: "10px",
            textAlign: "center",
            marginBottom: "20px",
          }}
          className="note"
        >
          * Inc. council application fee & first year's licence fee
        </p>
      </div>

      <div className="prices-content">
        <p>
          {" "}
          <strong>
            We Waive Our Approval fee's for clients who put theyre property
            under management with us
          </strong>{" "}
        </p>
        <p>
          your're council fees may also be paid in arrears with the revenue
          generated by the property.
        </p>
      </div>

      <div className="prices-content">
        <p>
          {" "}
          <strong>Want to Manage Your Property Yourself </strong>{" "}
        </p>
        <p>
          We are a partner of the Airbnb affiliate Program. If it is your first
          property to be listed on airbnb, airbnb will pay us a referral fee
          which will cover the cost of most approvals + your first years license
          and council fees.
        </p>
      </div>

      <button onClick={handleSubmit} className="cta-button">
        Get Your Approval Here
      </button>

      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Prices;
