// src/pages/Portfolio.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation'; 
import '../styles/Portfolio.css';
import SuccessModal from '../components/SuccessModal';
const Portfolio = () => {


  const [showModal, setShowModal] = useState(false);


  const handleSubmit =  (e) => {
      if (true) {
        setShowModal(true);
  
      }

  };

  return (
    <div className="portfolio">
      <Navigation /> 

      <div className="portfolio-header">
        <h1>Our Portfolio</h1>
      </div>

      <div className="portfolio-content">
        <p>Explore our recent projects and successful case studies:</p>

        <div className="portfolio-items">
          <div className="portfolio-item">
            <h2>Project 1</h2>
            <p>Description of Project 1, showcasing the work done and the results achieved.</p>
          </div>
          <div className="portfolio-item">
            <h2>Project 2</h2>
            <p>Description of Project 2, highlighting the solutions provided and client satisfaction.</p>
          </div>
          <div className="portfolio-item">
            <h2>Project 3</h2>
            <p>Description of Project 3, demonstrating the approach and success metrics.</p>
          </div>
    
        </div>
      </div>
      <button onClick={handleSubmit} className="cta-button">Get Your Approval Here</button>

      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
      {/* <Link to="/" className="back-home">Back to Home</Link> */}
    </div>
  );
};

export default Portfolio;
