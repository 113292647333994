import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Prices from './pages/Prices';
import Contact from './pages/Contact';
import Management from './pages/Management';
import Portfolio from './pages/Portfolio';
import Navigation from './components/Navigation';
// import { SendGridProvider } from './contexts/SendGridContext';
import './styles/App.css';

const App = () => {
  return (
    // <SendGridProvider>
      <Router>
        <div className="app">
          <Routes>
            <Route path="/" element={<Home />} />
             <Route path="/about" element={<About />} />
              <Route path="/prices" element={<Prices />} /> 
          <Route path="/contact" element={<Contact />} />
            <Route path="/management" element={<Management />} />
            <Route path="/portfolio" element={<Portfolio />} />  
          </Routes>
          <Navigation />
        </div>
      </Router>
    // </SendGridProvider>
  );
};

export default App;